import { initStore, SliceState, StoreState } from "./use-store";

const configureStore = () => {
  const actions = {
    TOKEN: (state: SliceState) => {
      return { ...state, token: "No Token" };
    },
    TO_EN: (state: SliceState) => {
      return { ...state, lang: "EN" };
    },
    TOGGLE: (state: SliceState) => {
      if (state.lang == "FR") {
        return { ...state, lang: "EN" };
      } else {
        return { ...state, token: "No Token" };
      }
    },
  };

  initStore("jwt_access", actions, { token: "No Token" });
};

export default configureStore;
