import React from "react";
import { defer, json, useLoaderData } from "react-router-dom";
import EventCard from "../components/EventCard";
import { EventInterface } from "../models/event";
import { SliceState, useStore } from "../store/use-store";

import classes from "./EventsSelection.module.css";

const EventsSelection: React.FC = () => {
  const [languageStore] = useStore("language") as [SliceState, any];
  const { eventInfos } = useLoaderData() as eventInfos;

  const hasSoonEvent = eventInfos.some((event) => event.status === "soon_subscriber");
  const hasFullEvent = eventInfos.some((event) => event.status === "full");
  const allEventsOver = eventInfos.every((event) => event.status === "over");

  return (
    <div className={classes.container}>
      {eventInfos.length > 0 && !hasFullEvent && !allEventsOver && !hasSoonEvent && (
        <>
          <h1>
            {languageStore.lang === "FR" && "Événements"}
            {languageStore.lang === "EN" && "Events"}
          </h1>
          {eventInfos.map(
            (event) =>
              (event.status === "publish" || event.status === "draft" || event.status === "soon") && (
                <div key={event.eventId}>
                  {event.status === "draft" && <h3 className={classes.preview}>(prévisualisation)</h3>}
                  {event.status === "soon" && <h3 className={classes.preview}>(prévisualisation - à paraître)</h3>}
                  <EventCard eventInfo={event} />
                </div>
              )
          )}
        </>
      )}
      {hasSoonEvent && (
        <div className={classes.noevents}>
          <h1>
            {languageStore.lang === "FR" && "Les inscriptions vont démarrer dans quelques instants."}
            {languageStore.lang === "EN" && "Registrations will start shortly."}
          </h1>
          <p>
            {languageStore.lang === "FR" && "Merci de votre patience."}
            {languageStore.lang === "EN" && "Thank you for your patience."}
          </p>
        </div>
      )}

      {hasFullEvent && (
        <div className={classes.noevents}>
          <h1>
            {languageStore.lang === "FR" && "L'évènement est d'ores et déjà complet."}
            {languageStore.lang === "EN" && "The event is now fully booked."}
          </h1>
          <p>
            {languageStore.lang === "FR" && "Merci et à bientôt."}
            {languageStore.lang === "EN" && "Thanks and see you soon."}
          </p>
        </div>
      )}

      {(allEventsOver || eventInfos.length === 0) && (
        <div className={classes.noevents}>
          <h1>
            {languageStore.lang === "FR" && "Aucun événement n'est prévu pour le moment."}
            {languageStore.lang === "EN" && "There is no event for now."}
          </h1>
          <p>
            {languageStore.lang === "FR" && "Merci et à bientôt."}
            {languageStore.lang === "EN" && "Thanks and see you soon."}
          </p>
        </div>
      )}
    </div>
  );
};

export default EventsSelection;

async function loadEvents() {
  try {
    const response = await fetch(process.env.REACT_APP_BACK_URL + "/event", {
      credentials: "include",
    });
    const data = await response.json();

    return data;
  } catch (error) {
    throw json({ message: "Erreur d'appel (loadEvents)" }, { status: 500 });
  }
}

export async function loader() {
  return defer({
    eventInfos: await loadEvents(),
  });
}

type eventInfos = {
  eventInfos: EventInterface[];
};
