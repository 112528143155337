const otherWebsites = {
  FR: [
    {
      name: "Disneyland Paris",
      url: "https://www.disneylandparis.com/fr-fr/",
    },
    {
      name: "Disneyland Paris Carrière",
      url: "http://careers.disneylandparis.com/fr",
    },
    {
      name: "Disneyland Paris Event Group",
      url: "https://disneylandparis-business.com/fr",
    },
    {
      name: "The Walt Disney Company",
      url: "https://www.thewaltdisneycompany.com/",
    },
    {
      name: "Disney Parks Blog",
      url: "https://disneyparks.disney.go.com/blog/topics/magic-moments/",
    },
    {
      name: "Disneyland News",
      url: "hhttps://disneylandnews.com/",
    },
    {
      name: "WDW News",
      url: "https://wdwnews.com/",
    },
    {
      name: "Disney Cruise Line",
      url: "http://dclnews.com/",
    },
  ],
  EN: [
    {
      name: "Disneyland Paris",
      url: "https://www.disneylandparis.com/en-gb/",
    },
    {
      name: "Disneyland Paris Career",
      url: "http://careers.disneylandparis.com/en",
    },
    {
      name: "Disneyland Paris Event Group",
      url: "https://disneylandparis-business.com/en",
    },
    {
      name: "The Walt Disney Company",
      url: "https://www.thewaltdisneycompany.com/",
    },
    {
      name: "Disney Parks Blog",
      url: "https://disneyparks.disney.go.com/blog/topics/magic-moments/",
    },
    {
      name: "Disneyland News",
      url: "hhttps://disneylandnews.com/",
    },
    {
      name: "WDW News",
      url: "https://wdwnews.com/",
    },
    {
      name: "Disney Cruise Line",
      url: "http://dclnews.com/",
    },
  ],
};

export default otherWebsites;