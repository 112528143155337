export const textFR = `
<p>Ce site est détenu et géré par Euro Disney Associés S.A.S. Une société immatriculée au Registre du commerce et des sociétés de Meaux sous le numéro 397 471 822 (SIREN), domiciliée à l'adresse 1 rond-point d’Isigny – 77700 Chessy, France, et au capital de 2 875 978 999,40 euros.</p>
    
<p>Numéro de TVA intracommunautaire : FR 07 397 471 822 Téléphone : +33. 1.64.74.40.00 Adresse e-mail : <a href="mailto:dlp.corporate.com@disney.com" target="_blank">dlp.corporate.com@disney.com</a> Rédacteur en chef : M Thomas Hecart, Vice-Président, Communication et Relations extérieures.</p>

<p>Licences L-D-2019-335, L-D-2022-003405, L-D-2022-003420, 003421, 003422, 03454, L-R-2022-678, 681, 684, 686, 689, 695, 696, 700, 702, 707, 712, 720, 725, 730 & 731.</p>

<p>Ce site Web est hébergé par la société Enterprise Technology Flex Systems Reliability Engineering Attn: Chris Putonen 820 S. Flower Street Burbank, CA 91502 1-818-238-2653</p>
`

export const textEN = `
<p>This website is owned and managed by Euro Disney Associés S.A.S., a company registered under the laws of France under number 397 471 822 (SIREN) at the trade and company register of Meaux, with registered head office at 1 rond-point d’Isigny – 77700 Chessy, France, and share capital of 2 875 978 999,40 euros.</p> 

<p>VAT Intercommunity number : FR 07 397 471 822 ; Phone : 00.33.164744000; email address : <a href="mailto:dlp.corporate.com@disney.com" target="_blank">dlp.corporate.com@disney.com</a> Chief Editor: Mr Thomas Hecart, Vice-Président, Communication.</p>

<p>Licences L-D-2019-335, L-D-2022-003405, L-D-2022-003420, 003421, 003422, 03454, L-R-2022-678, 681, 684, 686, 689, 695, 696, 700, 702, 707, 712, 720, 725, 730 & 731.</p>

<p>The website is hosted by Enterprise Technology Flex Systems Reliability Engineering Attn: Chris Putonen 820 S. Flower Street Burbank, CA 91502 1-818-238-2653</p>
`


export const contentFR = {
  title: "Mentions légales",
  paraph: textFR
}

export const contentEN = {
  title: "Legal notice",
  paraph: textEN
}