import facebookIcon from "../assets/images/facebook.svg";
import twitterIcon from "../assets/images/twitter.svg";

export const navItemsFR = [
  {
    url: "/conditions-d-utilisation",
    text: "Conditions Générales D’Utilisation",
  },
  {
    url: "/mentions-legales",
    text: "Mentions Légales",
  },
  {
    url: "https://privacy.thewaltdisneycompany.com/fr/regles-de-respect-de-la-vie-privee/",
    text: "Règles de respect de la vie privée",
  },
  {
    url: "https://www.disneylandparis.com/fr-fr/legal/charte-relative-aux-cookies/",
    text: "Charte relative aux cookies",
  },
];

export const navItemsEN = [
  {
    url: process.env.PUBLIC_URL + "/conditions-d-utilisation",
    text: "Terms of use",
  },
  {
    url: process.env.PUBLIC_URL + "/mentions-legales",
    text: "Legal Notice",
  },
  {
    url: "https://privacy.thewaltdisneycompany.com/en/",
    text: "Current Privacy Policy",
  },
  {
    url: "https://www.disneylandparis.com/en-gb/legal/cookies-policy/",
    text: "Cookies policy",
  },
];

export const socialsItems = [
  {
    url: "https://www.facebook.com/DisneylandParisfr/?rf=289225498166294",
    icon: facebookIcon,
  },
  {
    url: "https://twitter.com/DLPNews",
    icon: twitterIcon,
  },
];
