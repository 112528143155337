import { Form, useParams } from "react-router-dom";
import Button from "./Button";
import TimeSlots from "./form-components/TimeSlots";

import classes from "./BookForm.module.css";
import { SliceState, useStore } from "../store/use-store";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import Loading from "./Loading";
import { EventInterface, Slot } from "../models/event";
import FeedbackMessage from "./FeedbackMessage";
import { getDateEvent } from "../utils/date";

const BookForm: React.FC<{
  slots: Slot[];
  max: number;
  userAlreadyBooked: boolean | null;
  eventInfo: EventInterface;
  reloadData: () => void;
}> = ({ slots, max, userAlreadyBooked, eventInfo, reloadData }) => {
  const refSlot = useRef<HTMLInputElement>(null);
  const refQty = useRef<HTMLInputElement>(null);
  const refConsent = useRef<HTMLInputElement>(null);

  const [message, setMessage] = useState<{ status: "error" | "success"; text: string }>({ status: "error", text: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [successBooking, setSuccessBooking] = useState(false);
  const [soldOut, setSoldOut] = useState(false);
  const resetTimeSlotsRef = useRef<() => void>();
  const params = useParams();

  const [languageStore] = useStore("language") as [SliceState, any];

  useEffect(() => {
    if (eventInfo.soldout) {
      setSoldOut(true);
    }
  }, [eventInfo]);

  const noneValidSubmitHandler = () => {
    let mess = "";
    if (!refConsent.current?.checked) {
      if (languageStore.lang === "EN" || languageStore.lang === "ENG")
        mess += "You must consent to the terms to confirm your registration to this event. ";
      else mess += "Vous devez accepter les conditions pour vous inscrire à cet évènement. ";
    }

    if (!refSlot.current?.value) {
      if (languageStore.lang === "EN" || languageStore.lang === "ENG")
        mess += "You must choose one type of invitation. ";
      else mess += "Vous devez choisir un type d'invitation. ";
    }

    if (!refQty.current?.value || refQty.current.value === "0") {
      if (languageStore.lang === "EN" || languageStore.lang === "ENG")
        mess += "You must select more than zero number of guest. ";
      else mess += "Vous devez selectionner un nombre d'invité supérieur à zéro. ";
    }

    return mess;
  };

  const onSubmitHandler = async (event: SyntheticEvent) => {
    event.preventDefault();
    setIsLoading(true);
    const form = event.target as HTMLFormElement;

    const mess = noneValidSubmitHandler();

    if (mess.trim().length) {
      event.preventDefault();
      setMessage({
        status: "error",
        text: mess,
      });
      setIsLoading(false);
      return;
    }

    try {
      //TODO: remove the first part, it is manage in backend now / refactor dealing with error from backend
      const response = await fetch(process.env.REACT_APP_BACK_URL + "/event/" + params.eventId, {
        credentials: "include",
      });
      const eventResponse = await response.json();

      if (eventResponse.maxSpacesPerTickets[refSlot.current?.value as string] < +refQty.current?.value!) {
        // If there is no more spaces from this type, we refresh the data and reboot TimeSlots value
        let errorMess;
        if (languageStore.lang === "EN" || languageStore.lang === "ENG")
        errorMess = "We are sorry, but the number of invitations requested exceeds the number of spots available for this type of invitation.";
        else errorMess = "Nous sommes désolés, le nombre de places demandées est supérieur au nombre de places disponibles pour ce type d'invitation.";
        setMessage({
          status: "error",
          text: errorMess,
        });

        if (resetTimeSlotsRef.current) {
          resetTimeSlotsRef.current();
        }
        reloadData();
        setIsLoading(false);
      } else {
        const formData = {
          timeSlot: refSlot.current?.value as string,
          spaces: +refQty.current?.value!,
        };
        let url = process.env.REACT_APP_BACK_URL + "/book/" + params.eventId;

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
          credentials: "include",
        });

        if (response.status === 200) {
          const data = await response.json();
          setSuccessBooking(true);
          const timer = setTimeout(() => {
            setIsLoading(false);
          }, 1000);
          return () => clearTimeout(timer);
        }

        if (response.status === 404) {
          const data = await response.json();
          setSuccessBooking(false);
          setMessage({
            status: "error",
            text: data.message,
          });
          if (resetTimeSlotsRef.current) {
            resetTimeSlotsRef.current();
          }
          reloadData();
          setIsLoading(false);
        }

        if (!response.ok) {
          //TODO: Deal with error
          setIsLoading(false);
          console.log(response);
          return;
        }
      }
    } catch (error) {
      throw new Response("Vous devez être connecté à votre compte pour effectuer une réservation", { status: 401 });
    }
  };

  const onCheckBoxChangeHandler = (event: SyntheticEvent) => {
    if (!refConsent.current?.checked) {
      let mess;
      if (languageStore.lang === "EN" || languageStore.lang === "ENG")
        mess = "You must consent to the terms to confirm your regstration to this event.";
      else mess = "Vous devez accepter les conditions pour vous inscrire à cet évènement. ";
      setMessage({
        status: "error",
        text: mess,
      });
    } else {
      setMessage({
        status: "error",
        text: "",
      });
    }
  };

  const labelsFR = {
    title: "Inscription à cet évènement :",
    consent:
      "Je consens au traitement de mes informations personnelles dans le cadre de mon inscription à cet évènement.",
    submit: "Inscription",
    confirm: `Nous sommes ravis de vous confirmer votre inscription à l'événement Euro Disney Club qui aura lieu le ${getDateEvent(
      eventInfo.date,
      languageStore.lang
    )}. Vous recevrez un email automatique très prochainement.`,
    booked: `Nous sommes ravis de vous confirmer votre inscription à l'événement Euro Disney Club qui aura lieu le ${getDateEvent(
      eventInfo.date,
      languageStore.lang
    )}.`,
    soldout: "L'évènement est d'ores et déjà complet, merci et à très bientôt.",
  };

  const labelsEN = {
    title: "Register to this event :",
    consent: "I consent to the processing of my personal information as part of my registration for this event.",
    submit: "Submit",
    confirm: `We are delighted to confirm you registration to the Euro Disney Club event that will take place on ${getDateEvent(
      eventInfo.date,
      languageStore.lang
    )}. You will receive an automatic email very soon.`,
    booked: `We are delighted to confirm your registration to the Euro Disney Club event that will take place on ${getDateEvent(
      eventInfo.date,
      languageStore.lang
    )}.`,
    soldout: "The event is now fully booked, thanks and see you soon.",
  };

  let labels = labelsFR;
  if (languageStore.lang === "EN" || languageStore.lang === "ENG") labels = labelsEN;

  return (
    <>
      {!successBooking && !userAlreadyBooked && !soldOut && (
        <Form method="post" className={classes.form} onSubmit={onSubmitHandler}>
          <div className={classes.centeredContent}>
            <h3>{labels.title}</h3>
          </div>
          <TimeSlots
            slots={slots}
            refSlot={refSlot}
            refQty={refQty}
            max={max}
            maxSpacesPerTickets={eventInfo.maxSpacesPerTickets}
            onReset={(resetFn) => {
              resetTimeSlotsRef.current = resetFn;
            }}
          />
          <div className={classes.centeredContent}>
            <div>
              <input type="checkbox" name="consent" ref={refConsent} onChange={onCheckBoxChangeHandler} />
              <label htmlFor="consent" className={classes.consentLabel}>
                {labels.consent}
              </label>
            </div>
            {message.text && message.text.trim().length && (
              <div>
                <span className={message.status === "error" ? classes.error : classes.success}>{message.text}</span>
              </div>
            )}
            {isLoading && <Loading />}
            {!isLoading && (
              <Button style={{ width: "20vw", minWidth: "fit-content", marginTop: "2vw" }}>{labels.submit}</Button>
            )}
          </div>
        </Form>
      )}
      <div
        className={`${
          successBooking || isLoading || soldOut || userAlreadyBooked ? classes["feedback-container"] : ""
        }`}
      >
        {successBooking && isLoading && <Loading />}
        {!successBooking && !isLoading && soldOut && !userAlreadyBooked && (
          <FeedbackMessage text={labels.soldout} caution />
        )}
        {successBooking && !isLoading && <FeedbackMessage text={labels.confirm} />}
        {!successBooking && userAlreadyBooked && <FeedbackMessage text={labels.booked} />}
      </div>
    </>
  );
};

export default BookForm;
