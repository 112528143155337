import { useState, useEffect } from "react";

export interface SliceState {[key:string]:any}
export interface StoreState {[key:string]:SliceState};

type StoreAction = ((globalState:StoreState, payload:any) => SliceState)
interface StoreActions  {[key:string]:StoreAction}


let globalState:StoreState = {};
let listeners:{[key:string]:React.Dispatch<React.SetStateAction<StoreState>>[]} = {};
let actions:{[key:string]:StoreActions} = {};

export const useStore = (slice:string) => {
  if(!(slice in globalState) || !(slice in listeners) || !(slice in actions)) initStore(slice);
  const setState = useState(globalState[slice])[1];

  const dispatch = (actionIdentifier: string, payload:any) => {
      const newState = actions[slice][actionIdentifier](globalState[slice], payload);
      globalState[slice] = { ...globalState[slice], ...newState };
  
      for (const listener of listeners[slice]) {
        listener(globalState[slice]);
      }
  };

  useEffect(() => {
      listeners[slice].push(setState);

    return () => {
        listeners[slice] = listeners[slice].filter((li) => li !== setState);
    };
  }, [setState]);

  return [globalState[slice], dispatch];
};

export const initStore = (name:string, userActions?:StoreActions, initialState?:SliceState) => {
  if(!(name in globalState)) globalState[name] = {};
  if(!(name in listeners)) listeners[name] = [];
  if(!(name in actions)) actions[name] = {};


  let initState = {value: ""}

  let initActions =  {
    "SAVE": (state:SliceState, payload:any) => {
    console.log( "SAVING "+name, payload);
    return {...state, value:payload}
  }
}

  if (initialState) {
    initState = {...initState, ...initialState}
  }
  if(userActions)
    initActions = {...initActions, ...userActions}

  globalState[name] = {...initState};
  actions[name] = {...initActions};
};


