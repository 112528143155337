import React from 'react';
import BlurBox from '../layout/BlurBox';
import classes from "./Unauthorized.module.css";
import { Link } from 'react-router-dom';

const Unauthorized: React.FC<{title: string; message: string;}> = ({title, message}) =>  {
  return (
    <div className={classes.container}>
       <BlurBox>
        <h1>{title}</h1>
        <p>{message}</p>
        <button className={classes.button}>
          <Link to="/">Retour à l'accueil</Link>
        </button>
      </BlurBox>
    </div>
  );
};

export default Unauthorized;