import React from 'react';

import classes from './FeedbackMessage.module.css';

const FeedbackMessage: React.FC<{text: string, caution?: boolean}> = ({text, caution}) => {
  return (
    <div className={`${classes.message} ${caution ? classes.caution : ""}` }>
      <p>{text}</p>
    </div>
  );
};

export default FeedbackMessage;