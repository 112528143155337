import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

import classes from "./Button.module.css";

const Button: React.FC<Props> = ({ children, iswhite, islink, icon, style, onClick }) => {
  return (
    <button
      className={`${classes.button} ${iswhite ? classes.iswhite : ""}  ${islink ? classes.islink : ""}`}
      onClick={onClick}
      style={style}
    >
      {icon && <FontAwesomeIcon icon={icon} />}
      <span>{children}</span>
    </button>
  );
};

export default Button;

type Props = {
  children?: React.ReactNode;
  iswhite?: boolean;
  islink?: boolean;
  icon?: IconDefinition;
  style?: React.CSSProperties;
  onClick?: () => void;
};
