import React from "react";
import { Link, useRouteError } from "react-router-dom";
import BlurBox from "../layout/BlurBox";
import classes from './Error.module.css';

const Error: React.FC = () => {
  const error: any = useRouteError();
  let title = "Il semble qu'une erreur soit survenue !";
  let message = "Impossible de trouver la page demandée";

  if (error.status === 500) {
    message = error.data.message;
  }

  if (error.status === 404) {
    title = "Page non trouvée";
    message = "Il semble que la page demandée n'existe pas";
  }

  if (error.status === 401) {
    title = "Erreur d'identification";
    message = "Vous devez être connecté à votre compte pour effectuer une réservation";
  }

  return (
    <main className={classes.container}>
      <BlurBox>
        <h1>{title}</h1>
        <p>{message}</p>
        <button className={classes.button}>
          <Link to="/">Retour à l'accueil</Link>
        </button>
      </BlurBox>
    </main>
  );
};

export default Error;
