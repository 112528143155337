import React, { useState } from "react";
import LanguageFlag from "./LanguageFlag";
import FrFlag from "../../assets/images/FR_flag.svg";
import EnFlag from "../../assets/images/EN_flag.svg";
import { SliceState, useStore } from "../../store/use-store";
import classes from "./LanguageSwitch.module.css";

const languages: {lang: "FR" | "EN"; img: string}[] = [
  {
    lang: "FR",
    img: FrFlag,
  },
  {
    lang: "EN",
    img: EnFlag,
  },
];

const LanguageSwitch = () => {
  const [languageStore, dispatchLang] = useStore("language") as [
    SliceState,
    any
  ];
  const [isActive, setIsActive] = useState(false);

  function changeLanguageHandler(lang: "FR" | "EN") {
    dispatchLang("TO_" + lang);
  }

  function deployFlagsHandler(lang: "FR" | "EN") {
    if(isActive) {
      setIsActive(false);
      changeLanguageHandler(lang);
    } else {
      setIsActive(true)
    }
  }

  return (
    <nav className={classes.container}>
      <ul>
        {languages.map((language) => (
          <li
            key={language.lang}
            className={`${classes.flag_wrapper} ${
              (language.lang === languageStore.lang || isActive) && classes.active
            }`}
            onClick={() => deployFlagsHandler(language.lang)}
          >
            <LanguageFlag language={language} />
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default LanguageSwitch;
