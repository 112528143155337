import React from "react";
import { Link } from "react-router-dom";
import { faCalendarDays, faClock, faLocationDot, faTicket } from "@fortawesome/free-solid-svg-icons";

import classes from "./EventCard.module.css";
import Button from "./Button";
import { EventInterface } from "../models/event";
import IconText from "./IconText";
import { SliceState, useStore } from "../store/use-store";
import { getDateEvent, getHourEvent } from "../utils/date";

const EventCard: React.FC<Props> = ({ eventInfo }) => {
  const [languageStore] = useStore("language") as [SliceState, any];

  return (
    <article className={classes.container}>
      <div className={classes.image_wrapper}>
        <img src={languageStore.lang === "EN" ? eventInfo.imgUrl_en : eventInfo.imgUrl} alt={eventInfo.name} />
      </div>
      <div className={classes.infos_wrapper}>
        <div>
          <Link to={`/${eventInfo.eventId}`}>
            <h3 dangerouslySetInnerHTML={{
            __html:(languageStore.lang === "EN" && eventInfo.version_en) ? eventInfo.version_en["00_title_en"] : eventInfo.name}}></h3>
          </Link>
        </div>
        <div className={classes.meta_wrapper}>
          <IconText icon={faCalendarDays} text={getDateEvent(eventInfo.date, languageStore.lang)!} />
          <IconText icon={faClock} text={`${getHourEvent(eventInfo.timeStart, languageStore.lang)} - ${getHourEvent(eventInfo.timeEnd, languageStore.lang)}`} />
          {eventInfo.location?.locationName && (
            <IconText icon={faLocationDot} text={eventInfo.location?.locationName} style={{ fontStyle: "italic" }} />
          )}
        </div>
        <Link to={`/${eventInfo.eventId}`}>
          <Button>
            <IconText
              icon={faTicket}
              text={languageStore.lang === "EN" ? "Book now" : "Réservez dès maintenant"}
              styleIcon={{ transform: "rotate(50deg)", marginRight: "5px" }}
            />
          </Button>
        </Link>
      </div>
    </article>
  );
};

export default EventCard;

type Props = {
  eventInfo: EventInterface;
};
