import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import RootLayout from "./layout/RootLayout";
import Error from "./pages/Error";
import SingleEvent, { loader as eventDetailLoader } from "./pages/SingleEvent";
import Login, { action as LoginFormAction, loader as userLoader } from "./pages/Login";
import EventsSelection, { loader as eventsLoader } from "./pages/EventsSelection";
import ProtectedRoutes, { loader as userAuthLoader } from "./layout/ProtectedRoutes";
import ForgotPassword, { action as ForgotPasswordFormAction } from "./pages/ForgotPassword";
import RequestNewPassword, { action as RequestPasswordFormAction } from "./pages/RequestNewPassword";
import LegalNotice from "./pages/Legals/LegalNotice";
import TermsOfUse from "./pages/Legals/TermsOfUse";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoutes>
        <RootLayout />
      </ProtectedRoutes>
    ),
    errorElement: <Error />,
    loader: userAuthLoader,
    children: [
      { index: true, element: <EventsSelection />, loader: eventsLoader },
      { path: "/:eventId", element: <SingleEvent />, loader: eventDetailLoader },
    ],
  },
  {
    path: "/login",
    element: <RootLayout />,
    errorElement: <Error />,
    children: [
      { index: true, element: <Login />, action: LoginFormAction, loader: userLoader },
      { path: "forgot-password", element: <ForgotPassword />, action: ForgotPasswordFormAction },
      { path: "request-password", element: <RequestNewPassword />, action: RequestPasswordFormAction },
      { path: "mentions-legales", element: <LegalNotice /> },
      { path: "request-password", element: <RequestNewPassword />, action: RequestPasswordFormAction },
    ],
  },
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <Error />,
    children: [
      { path: "mentions-legales", element: <LegalNotice /> },
      { path: "conditions-d-utilisation", element: <TermsOfUse /> },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
