import LanguageSwitch from "../components/lang-switch-components/LanguageSwitch";

import classes from "./Header.module.css";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header className={classes.container}>
      <div className={classes.side_element}>
        <LanguageSwitch />
      </div>
      <Link to="/">
        <img src="/images/Logo-eurodisneyclub.png" alt="logo EurodisneyClub" />
      </Link>
      <div className={classes.side_element}></div>
    </header>
  );
};

export default Header;
