import { json } from "react-router-dom";

export async function loadLoginInfos() {
  try {
    const response = await fetch(process.env.REACT_APP_BACK_URL + "/user/checkauth", {
      credentials: "include",
    });
    const data = await response.json();

    return data;
  } catch (error) {
    throw json({ message: "Erreur d'appel (loadLoginInfo)" }, { status: 500 });
  }
}