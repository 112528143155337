import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

import classes from "./IconText.module.css";

const IconText: React.FC<Props> = ({ icon, text, style, styleIcon }) => {
  return (
    <div className={classes.container} style={style}>
      {icon && <FontAwesomeIcon icon={icon} style={styleIcon} />}
      <span>{text}</span>
    </div>
  );
};

export default IconText;

type Props = {
  icon?: IconDefinition;
  text: string;
  style?: React.CSSProperties;
  styleIcon?: React.CSSProperties;
};
