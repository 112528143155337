import { SliceState, useStore } from "../../store/use-store";
import { contentFR, contentEN } from "../../data/legal-notice";
import classes from './Legals.module.css'
import Title from "../../components/Title";

const LegalNotice = () => {
  const [languageStore] = useStore("language") as [SliceState, any];

  let content = contentFR;
  if (languageStore.lang === "EN" || languageStore.lang === "ENG") content = contentEN;

  return (
    <>
    <Title title={content.title} style={{margin: "50px 0 10px"}} />
    <div className={classes.container} dangerouslySetInnerHTML={{ __html: content.paraph }} />
  </>
  );
};

export default LegalNotice;
