const links = {
  privacy: {
    fr: '<a href="https://privacy.thewaltdisneycompany.com/fr/regles-de-respect-de-la-vie-privee/" target="_blank" >Règles de respect de la vie privée</a>',
    en: '<a href="https://privacy.thewaltdisneycompany.com/en/" target="_blank">Privacy Policy</a>'
  },
  cookies: {
    fr: '<a href="https://www.disneylandparis.com/fr-fr/legal/charte-relative-aux-cookies/" target="_blank">Modalités relatives aux cookies</a>',
    en: '<a href="https://www.disneylandparis.com/en-gb/legal/cookies-policy/" target="_blank">Cookies Policy</a>'
  },
  contact : '<a href="mailto:DLP-DONNEESPERSO-GUEST@disney.com" target="_blank">DLP-DONNEESPERSO-GUEST@disney.com</a>',
  dataprotection: '<a href="mailto:dataprotection@disney.co.uk" target="_blank">dataprotection@disney.co.uk</a>',
  representatives: {
    fr: '<a href="https://media.disneylandparis.com/d4th/fr-fr/images/EU_Representatives_french_tcm808-217295.pdf" target="_blank">ici</a>',
    en: '<a href="https://media.disneylandparis.com/d4th/en-gb/images/EU_Representatives_english_tcm752-217295.pdf" target="_blank">here</a>'
  },
  commissioner_office : '<a href="https://ico.org.uk/for-the-public" target="_blank">https://ico.org.uk/for-the-public</a>'
}

let textFr = `
En vous connectant à ce compte, vous reconnaissez que vous avez lu les ${links.privacy.fr}, et les ${links.cookies.fr}.
Comment nous utilisons vos informations personnelles et quels sont vos droits :
Vos informations personnelles sont collectées et contrôlées par Euro Disney Associés SAS, situé au 1 rond-point d’Isigny, 77700 Chessy, aux fins d’identification et de gestion des inscriptions des membres de l’Euro Disney Club aux événements privés qui leur sont dédiés et des éventuels tirages au sorts. Ces données ne seront conservées que le temps nécessaire à la gestion des inscriptions.
Vous disposez d'un certain nombre de droits, notamment celui de demander l'accès à, de modifier ou de supprimer vos informations personnelles en contactant ${links.contact};
Vous pouvez contacter notre délégué à la protection des données par e-mail à l'adresse : ${links.dataprotection}.
Si vous êtes dans l'UE, vous pouvez nous contacter via nos représentants locaux. Pour plus d'informations sur nos représentants, veuillez cliquer ${links.representatives.fr}.

Vous avez le droit de déposer une plainte auprès de l’Autorité de Contrôle de votre lieu de résidence ou auprès de l'Information Commissioner's Office du Royaume-Uni : ${links.commissioner_office}.
`
export const contentFR = textFr.split("\n").map(para => `<p>${para}</p>`).join("");

let textEn = `
By connecting to this account, you are acknowledging that you have read our ${links.privacy.en}, and ${links.cookies.en}. 
How we use your personal information and your rights:
Your personal information is collected and controlled by Euro Disney Associés SAS, located 1 rond-point d'Isigny, 77700 Chessy, France, for the purpose of your identification and the management of the registration of Euro Disney Club members to the private events that are dedicated to them and to draws. Your data will be stored only for the duration necessary to the management of the registrations. 
You have a number of rights, including the right to request access to, change, or remove your personal information by contacting ${links.contact}.
Our Data Protection Officer can be contacted by emailing: ${links.dataprotection}.
If you are in the EU, you may reach us through our local Representatives. For more information about our Representatives please check ${links.representatives.en}.

You have a right to lodge a complaint with your local Data Protection Supervisory Authority or with the UK Information Commissioner’s Office: ${links.commissioner_office}.
`

export const contentEN = textEn.split("\n").map(para => `<p>${para}</p>`).join("");