import BlurBox from "../layout/BlurBox";
import { ActionFunctionArgs, useActionData } from "react-router-dom";
import { SliceState, useStore } from "../store/use-store";
import { useQueryParams } from "../hooks/useQueryParams";
import { useEffect, useState } from "react";
import AuthFormsContent from "../components/AuthFormsContent";

const ForgotPassword = () => {
  const [error, setError] = useState(false);
  const [languageStore] = useStore("language") as [SliceState, any];
  let query = useQueryParams();
  const actionData = useActionData() as { error?: boolean; message: string; formSend?: boolean };

  useEffect(() => {
    if (actionData && actionData.error) {
      setError(true);
    }
  }, [actionData]);

  function errorHandler() {
    setError(false);
  }

  const labelsFR = {
    title: "Changement de mot de passe",
    subtitle: "Veuillez renseigner votre nouveau mot de passe.",
    placeholderEmail: "Adresse e-mail",
    placeholderPassword: "Nouveau mot de passe",
    placeholderConfirmPsw: "Confirmez mot de passe",
    button: "Modifier mot de passe",
    leave: { url: "/login", text: "Retourner à la page connexion" },
    formSent: "Votre mot de passe a bien été mis à jour, vous pouvez maintenant vous connecter.",
  };

  const labelsEN = {
    title: "Updating password",
    subtitle: "Please enter your new password.",
    placeholderEmail: "Email adress",
    placeholderPassword: "New password",
    placeholderConfirmPsw: "Confirm password",
    button: "Change password",
    leave: { url: "/login", text: "Go back to login page" },
    formSent: "Your password has been updated, you can now login.",
  };

  let labels = labelsFR;
  if (languageStore.lang === "EN" || languageStore.lang === "ENG") labels = labelsEN;
  return (
    <BlurBox>
      <AuthFormsContent
        type="forgotPassword"
        labels={labels}
        error={{ status: error, message: actionData?.message }}
        errorHandler={errorHandler}
        dataForm={actionData}
        resetToken={query.get("resetToken")}
      />
    </BlurBox>
  );
};

export default ForgotPassword;

export async function action({ request, params }: ActionFunctionArgs) {
  console.log("form submitted");
  const data = await request.formData();

  const formData = {
    password: data.get("password"),
    confirmPassword: data.get("confirm-password"),
    token: data.get("token"),
  };

  if (!formData.password || !formData.confirmPassword) {
    return { error: true, message: "Veuillez renseigner un nouveau mot de passe" };
  }

  if (formData.password !== formData.confirmPassword) {
    return { error: true, message: "Les deux champs mot de passe doivent être identiques" };
  }

  console.log(formData);

  let url = process.env.REACT_APP_BACK_URL + "/user/reset-password";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(formData),
  });

  if (response.status === 200) {
    console.log(response);
    return { formSend: true, message: "Félicitation, vous mot de passe a été modifié avec succès !" };
    // return redirect("/login");
  }

  if (!response.ok) {
    console.log(response);
    return response;
    //TODO: Deal with error
  }
}
