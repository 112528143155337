import { Outlet, useSearchParams } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { useEffect } from "react";
import { SliceState, useStore } from "../store/use-store";

const RootLayout: React.FC = () => {
  const [languageStore, dispatchLang] = useStore("language") as [SliceState, any];
  const [searchParams] = useSearchParams();
  const lang = searchParams.get('lang');

  useEffect(() => {
    if (lang && lang === "en") {
      dispatchLang("TO_EN");
    }
  }, [lang]);

  return (
    <>
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default RootLayout;
