import React, { useEffect, useState } from "react";
import { useLoaderData, defer, json, LoaderFunctionArgs, useNavigate } from "react-router-dom";
import EventDetails from "../components/EventDetails";
import BookForm from "../components/BookForm";
import { EventInterface } from "../models/event";
import classes from "./SingleEvent.module.css";
import Unauthorized from "../components/Unauthorized";
import { SliceState, useStore } from "../store/use-store";

const SingleEvent: React.FC = () => {
  const [languageStore] = useStore("language") as [SliceState, any];
  const { eventInfos } = useLoaderData() as EventInfosType;
  const { userBooking } = useLoaderData() as BookInfosType;
  const [currentEvent, setCurrentEvent] = useState<EventInterface | null>(null);
  const [userAlreadyBooked, setUserAlreadyBooked] = useState<boolean | null>(null);
  const [unauthorized, setUnauthorized] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (eventInfos.status === "unauthorized") {
      setUnauthorized(true);
    } else if (eventInfos.status === "full") {
      navigate('/');
    } else {
      setCurrentEvent(eventInfos);
    }

  }, [eventInfos]);

  useEffect(() => {
    setUserAlreadyBooked(userBooking);
  }, [userBooking]);

  const reloadData = async () => {
    const updatedEvent = await loadEvent(eventInfos.eventId);
    setCurrentEvent(updatedEvent);
  };

  return (
    <>
      {unauthorized && (
        <Unauthorized
          title={languageStore.lang === "FR" ? "Événement inaccessible" : "Event not available yet"}
          message={
            languageStore.lang === "FR"
              ? "Vous n'avez pas accès à cette événement pour le moment. Merci de revenir plus tard."
              : "You don't have access to this event at the moment. Please come back later."
          }
        />
      )}
      {!unauthorized && (
        <div className={classes.container}>
          {currentEvent && <EventDetails eventInfo={currentEvent} />}
          {currentEvent && (
            <BookForm
              slots={currentEvent?.tickets}
              max={currentEvent?.maxSpacesPerBooking}
              userAlreadyBooked={userAlreadyBooked}
              eventInfo={currentEvent}
              reloadData={reloadData}
            />
          )}
        </div>
      )}
    </>
  );
};

async function loadEvent(id: string) {
  try {
    const response = await fetch(process.env.REACT_APP_BACK_URL + "/event/" + id, {
      credentials: "include",
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    throw json(
      { message: "Une erreur s'est produite. L'événement n'existe pas ou bien vous n'êtes pas connecté" },
      { status: 500 }
    );
  }
}

async function loadUserBooking(eventId: string) {
  try {
    const response = await fetch(process.env.REACT_APP_BACK_URL + "/book/" + eventId, {
      credentials: "include",
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw json({ message: "Est ce que ça dit que ça marche pas ? (loadUserBooking)" }, { status: 500 });
  }
}

export async function loader({ params }: LoaderFunctionArgs) {
  if (params.eventId) {
    return defer({
      eventInfos: await loadEvent(params.eventId),
      userBooking: await loadUserBooking(params.eventId),
    });
  }
}

type EventInfosType = {
  eventInfos: EventInterface;
};
type BookInfosType = {
  userBooking: boolean;
};

export default SingleEvent;
