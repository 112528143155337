import React from "react";
import { Form, Link } from "react-router-dom";
import Title from "./Title";
import classes from "./AuthFormsContent.module.css";
import Button from "./Button";

type Props = {
  type: "login" | "requestNewPassword" | "forgotPassword";
  labels: {
    title: string;
    subtitle: string;
    asterisk?: string;
    placeholderEmail?: string;
    placeholderPassword?: string;
    placeholderConfirmPsw?: string;
    button: string;
    leave: { url: string; text: string };
    formSent?: string;
  };
  error: {
    status: boolean;
    message: string;
  };
  errorHandler: () => void;
  dataForm?: { error?: boolean; formSend?: boolean; message: string; admin?: boolean; url?: string };
  resetToken?: string | null;
};

const AuthFormsContent: React.FC<Props> = ({ type, labels, error, errorHandler, dataForm, resetToken }) => {
  let FormInputs;

  if (type === "login") {
    FormInputs = (
      <>
        <input className={classes.input} type="text" id="email" name="email" placeholder={labels.placeholderEmail} />
        <input
          className={classes.input}
          type="password"
          id="password"
          name="password"
          placeholder={labels.placeholderPassword}
        />
      </>
    );
  }

  if (type === "requestNewPassword") {
    FormInputs = (
      <input className={classes.input} type="text" id="email" name="email" placeholder={labels.placeholderEmail} />
    );
  }

  if (type === "forgotPassword") {
    FormInputs = (
      <>
        {resetToken && <input type="hidden" name="token" value={resetToken} />}
        <input
          className={classes.input}
          type="password"
          id="password"
          name="password"
          placeholder={labels.placeholderPassword}
        />
        <input
          className={classes.input}
          type="password"
          id="confirm-password"
          name="confirm-password"
          placeholder={labels.placeholderConfirmPsw}
        />
      </>
    );
  }

  return (
    <>
      <Title title={labels.title} />
      {!dataForm?.formSend && (
        <>
          <p className={classes.subtitle}>{labels.subtitle}</p>
          <p className={classes.asterisk}>{labels.asterisk}</p>
          <Form method="post" className={classes.form} onChange={errorHandler}>
            {FormInputs}
            {error.status && (
              <p className={classes.error}>{error.status ? error.message : "Une erreur est survenue"}</p>
            )}
            <Button iswhite style={{ marginTop: "25px", fontWeight: "500", fontSize: "12px" }}>
              {labels.button}
            </Button>
          </Form>
          <Button islink style={{ fontSize: "12px", textTransform: "unset" }}>
            <Link to={labels.leave.url}>{labels.leave.text}</Link>
          </Button>
        </>
      )}
      {dataForm?.formSend && !dataForm.admin && (
        <>
          <p className={classes.subtitle}>{labels.formSent}</p>
          <Button islink style={{ fontSize: "12px", textTransform: "unset", marginTop: "50px" }}>
            <Link to={"/login"}>{labels.leave.text}</Link>
          </Button>
        </>
      )}

      {dataForm?.formSend && dataForm.admin && (
        <>
          <p className={classes.subtitle}>{dataForm.message}</p>
          <Button islink style={{ fontSize: "17px", width:"100%", border: "1px solid", textTransform: "unset", marginTop: "50px" }}>
            <Link to={dataForm.url!} target="_blank" rel="noreferrer">
              Changement de mot de passe administrateur
            </Link>
          </Button>
        </>
      )}
    </>
  );
};

export default AuthFormsContent;
