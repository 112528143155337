import { useState, SyntheticEvent, RefObject, useEffect } from "react";
import { SliceState, useStore } from "../../store/use-store";

import classes from "./TimeSlots.module.css";
import { Slot } from "../../models/event";

const TimeSlots: React.FC<{
  slots: Slot[];
  refSlot: RefObject<HTMLInputElement>;
  refQty: RefObject<HTMLInputElement>;
  max: number;
  maxSpacesPerTickets: { [key: string]: number };
  onReset: (resetFunction: () => void) => void
}> = ({ slots, refSlot, refQty, max, maxSpacesPerTickets, onReset }) => {
  const [choosedSlot, setChoosedSlot] = useState<string | undefined>(undefined);
  const [choosedQty, setChoosedQty] = useState<number>(0);

  const [languageStore] = useStore("language") as [SliceState, any];

    // Reset Qty if the function is called in BookForm (after an error)
    const resetChoosedQty = () => {
      setChoosedQty(0);
    };
  
    useEffect(() => {
      onReset(resetChoosedQty);
    }, [onReset]);

  const secondOnChangeHandler = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    setChoosedSlot(target.name);
    setChoosedQty(+target.value);
  };

  const changeQtyHandler = (slot: string, type: "increase" | "decrease", maxSlot: number) => {
    setChoosedSlot(slot);
    if (maxSpacesPerTickets[slot] <= 0) {
      setChoosedQty(0);
      return;
    }
    switch (type) {
      case "increase":
        if (choosedQty < maxSlot) {
          setChoosedQty(choosedQty + 1);
        }
        break;
      case "decrease":
        if (choosedQty > 0) {
          setChoosedQty(choosedQty - 1);
        }
        break;
      default:
        return;
    }
  };

  return (
    <div className={classes.container}>
      <input type="hidden" name="slot_name" value={choosedSlot ? choosedSlot : ""} ref={refSlot} />
      <input type="hidden" name="slot_qty" value={choosedQty ? choosedQty : "0"} ref={refQty} />
      {slots.map((slot) => {
        const maxSlot = maxSpacesPerTickets[slot.ticketName] < max ? maxSpacesPerTickets[slot.ticketName] : max;
        let label = slot.labelFR;
        if (languageStore.lang === "EN" || languageStore.lang === "ENG") label = slot.labelEN;

        return (
          <div
            key={slot.ticketName}
            //Disable the slot if there is no places remain or if an other slot has been selected
            className={`${classes["slot-box"]} ${(maxSpacesPerTickets[slot.ticketName] <= 0 || (choosedSlot !== slot.ticketName && choosedSlot !== undefined && choosedQty > 0)) ? classes.disabled : ""}`}
          >
            {maxSpacesPerTickets[slot.ticketName] <= 0 && (
              <p className={classes["soldout-ticket"]}>{languageStore.lang === "EN" ? "soldout" : "Complet"}</p>
            )}
            <p className={classes["slot-label"]}>{label}</p>
            <div className={classes["input-wrapper"]}>
              <div
                className={`${classes.button} ${choosedQty <= 0 ? classes.disabled : ""}`}
                onClick={() => changeQtyHandler(slot.ticketName, "decrease", maxSlot)}
              >
                -
              </div>
              <input
                type="number"
                //If remaining places are smaller than max
                max={maxSlot}
                min="0"
                name={slot.ticketName}
                value={choosedSlot && choosedSlot !== slot.ticketName ? "0" : choosedQty}
                onChange={secondOnChangeHandler}
                className={classes["slot-input"]}
                disabled={maxSpacesPerTickets[slot.ticketName] > 0 ? false : true}
              />
              <div
                className={`${classes.button} ${choosedQty === max ? classes.disabled : ""}`}
                onClick={() => changeQtyHandler(slot.ticketName, "increase", maxSlot)}
              >
                +
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TimeSlots;
