import React from 'react';

import classes from "./BlurBox.module.css";

const BlurBox: React.FC<{children: React.ReactNode}> = ({children}) => {
  return (
    <div className={classes.container}>
      {children}
    </div>
  );
};

export default BlurBox;