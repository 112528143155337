import React, { useState } from "react";
import { Link } from "react-router-dom";

import Button from "../components/Button";
import classes from "./Footer.module.css";
import { SliceState, useStore } from "../store/use-store";
import otherWebsites from "../data/other-website-footer";
import { navItemsFR, navItemsEN, socialsItems } from "../data/nav-items-footer";

const Footer = () => {
  const [submenuIsActive, setSubmenuIsActive] = useState<boolean>(false);
  const [languageStore] = useStore("language") as [SliceState, any];

  let navItems = navItemsFR;
  if (languageStore.lang === "EN" || languageStore.lang === "ENG") navItems = navItemsEN;

  return (
    <footer className={classes.container}>
      <nav>
        <ul className={classes.menu}>
          {navItems.map((navItem, index) => (
            <li key={index}>
              <Link to={navItem.url}>{navItem.text}</Link>
            </li>
          ))}
          <li className={classes.btn_othersites}>
            <Button onClick={() => setSubmenuIsActive((prev) => !prev)}>
              {languageStore.lang === "FR" && "Autres Sites Disney"}
              {languageStore.lang === "EN" && "Other Disney Website"}
            </Button>
            {submenuIsActive && (
              <ul className={classes.submenu}>
                {(languageStore.lang === "FR" ? otherWebsites.FR : otherWebsites.EN).map((website, index) => (
                  <li key={index}>
                    <Link to={website.url}>{website.name}</Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        </ul>
        <ul className={classes.socials}>
          {socialsItems.map((socialItem, index) => (
            <li key={index}>
              <Link to={socialItem.url} target="_blank">
                <span style={{ backgroundImage: `url(${socialItem.icon})` }}></span>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <p>© Disney • Pixar © & TM Lucasfilm Ltd. © MARVEL. Tous droits réservés.</p>
    </footer>
  );
};

export default Footer;
