const textFR = `
<h2>À LIRE ATTENTIVEMENT AVANT TOUTE UTILISATION DE CE SITE </h2>

<p>En utilisant ce site, vous signifiez implicitement votre accord eu égard à ses conditions d’utilisation. Si vous n’êtes pas d’accord avec les conditions stipulées ci-après, nous vous prions de ne pas utiliser ce site. </p>

<h3>ACCEPTATION DES CONDITIONS D’UTILISATION DU SITE INTERNET </h3>

<p>Le site Internet ${process.env.REACT_APP_PUBLIC_URL}  (ci-après « le Site Internet ») est mis à votre disposition sous réserve que vous vous engagiez à prendre connaissance des présentes conditions d’utilisation du site Internet (les « Conditions d’Utilisation »).  </p>

<p>Euro Disney Associés S.A.S. se réserve le droit de modifier à tout moment et sans préavis les Conditions Générales d’Utilisation. Vous devez consulter régulièrement les Conditions Générales d’Utilisation pour prendre connaissance des modifications qui ont pu être effectuées. Pour savoir si les Conditions Générales d’Utilisation ont été modifiées depuis votre dernière visite, veuillez-vous référer à la date de dernière mise à jour figurant en haut de cette page. </p>

 

<h3>RESTRICTIONS POUR L’UTILISATION DES DOCUMENTS </h3>

<p>Le Site Internet fournit les services suivants : </p>

<p>L’accès à l’information sur l’entreprise Euro Disney Associés S.A.S. et autres contenus relatifs aux Parcs Disneyland Paris et aux personnages © Disney • Pixar © & TM Lucasfilm Ltd. © MARVEL. Ces informations peuvent être librement consultées par tous les visiteurs du Site Internet.  
Des fonctionnalités mises à la disposition des utilisateurs du Site Internet (accessibilité, demande de contact, etc). L’utilisateur peut par ailleurs partager certains contenus du site internet via des sites tiers qui ne sont pas édités par Euro Disney Associés S.A.S. (YouTube, Facebook, Twitter, LinkedIn, Instagram, Pinterest).  
La modification de ces documents tels que l’ensemble des copyrights et autres mentions de propriété une infraction au copyright de Disney Enterprises, Inc. (le concédant de licence) et à ses autres droits de propriété.  </p>

<h3>PROPRIÉTÉ INTELLECTUELLE </h3>

<p>Euro Disney Associés S.A.S. est titulaire de tous les droits nécessaires à la diffusion et l’exploitation du Site Internet et des éléments le composant (notamment les informations, textes, images, personnages, vidéos, logos, dessins, marques, infographies, data visualisation, etc. – ci-après les « éléments du Site Internet »). La reproduction de tout contenu publié sur notre Site Internet est seulement autorisée aux fins exclusives d’information, non commerciales et dans les limites et condiftions des présentes Conditions Générales d’Utilisation, ce pour la durée pendant laquelle le Site Internet sera disponible. Les Éléments du site peuvent être utilisées sous réserve qu’ils ne soient pas modifiés et que l’ensemble des mentions de propriété intellectuelle (notamment le copyright) soit conservé. Euro Disney Associés S.A.S. autorise l’utilisation des communiqués de presse, infographies, dossiers de presse, chiffres clés publiés sur le Site Internet par des journalistes professionnels uniquement dans le cadre de leur activité. </p>

<h3>LIENS HYPERTEXTES </h3>

<p>Certains des liens hypertextes disponibles sur le Site Internet renvoient vers des sites tiers qui ne sont pas édités par Euro Disney Associés S.A.S. (YouTube, Facebook, Twitter, LinkedIn, etc.). En conséquence, Euro Disney Associés S.A.S. exclut toute responsabilité concernant (i) tout contenu ou service proposé sur ces sites tiers, (ii) toute perte ou tout dommage qui pourrait résulter de votre utilisation de ces sites tiers et (iii) les modalités de traitement de vos données sur les sites tiers. L’insertion, sur des sites tiers, de liens renvoyant vers des pages du Site Internet est autorisée sous réserve (i) de ne pas supprimer ou modifier de quelque manière que ce soit, la taille ou l’apparence des éléments du Site Internet, (ii) de ne pas utiliser les personnages, noms, marques et autres signes distinctifs pour lesquels Euro Disney Associés S.A.S. détient les droits d’utilisation et d’exploitation sur le Site Internet et (iii) que le site tiers où figure le lien Site Internet soit conforme à la réglementation applicable et aux bonnes mœurs, ne propose pas de contenu diffamatoire ou injurieux et ne soit pas à caractère pornographique. </p>

<h3>DISPONIBILITES DU SITE INTERNET</h3>

<p>Euro Disney Associés S.A.S. ne garantit en aucun cas que l’accès au Site Internet soit possible de manière ininterrompue, instantanée, automatique pendant toute la durée de mise en ligne. Euro Disney Associés S.A.S. se réserve par ailleurs le droit de rendre le Site Internet indisponible à tout moment, de manière temporaire ou définitive.  </p>

<h3>ÉTENDUE DES OBLIGATIONS DE Disneyland Paris </h3>

<p>Ce site est contrôlé et géré par Disneyland Paris, depuis ses bureaux de Chessy, Marne-la-Vallée. Toutes les informations accessibles sur le site sont fournies en l’état. Les documents proposés sur ce site ont uniquement pour but d’informer et de présenter les activités de Disneyland Paris. Remarque : certaines des informations sur ce site peuvent être erronées et Disneyland Paris ne fournit aucune garantie, expresse ou implicite, quant à leur contenu. Disneyland Paris se réserve le droit de modifier ces informations à tout moment, notamment lors des mises à jour du site. Disneyland Paris ne garantit pas non plus la conformité des documents proposés sur le site aux législations locales applicables si vous y accédez depuis d’autres régions. </p>

<h3>GARANTIE ET RESPONSABILITÉ </h3>

<p>Euro Disney Associés S.A.S.  ne saurait être tenu responsable des dommages indirects résultant de l’utilisation ou de l’impossibilité d’accéder et d’utiliser tout ou partie du Site Internet, y compris notamment tout ou partie des services qui y sont proposés. Euro Disney Associés S.A.S.  exclut toutes garanties concernant la disponibilité et la conformité des fonctions du Site Internet et l’aptitude du site Internet à répondre aux attentes ou aux besoins recherchés par les visiteurs et utilisateurs. Euro Disney Associés S.A.S.  ne garantit pas que les fonctionnalités et services proposés par le Site Internet seront assurés sans interruption ni erreur, ni que les défauts seront corrigés ou que le Site Internet ou le serveur qui les met à disposition sont exempts de virus ou autres composants dangereux. </p>

<p>Vous êtes seul responsable de l’usage des informations qui vous sont communiquées. Il vous appartient de veiller à ne transmettre sur le Site Internet aucune information étant susceptible d’entraîner une responsabilité civile ou pénale et vous vous engagez, en utilisant ce Site Internet, à ne pas transmettre d’informations illégales, contraires à l’ordre public, diffamatoires, etc. Étant en parfaite connaissance des caractéristiques du site disneylandparis-news.com, vous renoncez à engager la responsabilité de Euro Disney Associés S.A.S.  en rapport avec l’utilisation des informations proposées sur le Site Internet. Euro Disney Associés S.A.S.  décline par conséquent toute responsabilité en cas de dommages directs ou indirects résultant de l’utilisation du Site Internet ou de l’impossibilité à l’utiliser. </p>

<h3>DUREE DES CONDITIONS D’UTILISATION </h3>

<p>Les présentes Conditions Générales d’Utilisation s’appliquent pour la durée du Site Internet, dans la limite de sa durée de mise en ligne. </p>

<h3>LOI APPLICABLE </h3>

<p>Sauf dispositions contraires d’ordre public de la loi locale, les présentes Conditions Générales d’Utilisation ainsi que vos relations avec Euro Disney Associés S.A.S.  dans le cadre du Site Internet sont soumises au droit français et tout litige sera de la compétence des juridictions françaises.  </p> `


export const textEN = `
<h2>PLEASE READ THESE CONDITIONS CAREFULLY BEFORE YOU USE THIS SITE</h2>

<p>Use of this site is subject to your agreeing to adhere to the conditions for use. If you do not agree with the terms stated below, please do not use this site.</p>
 
<h3>Acceptance of the terms of use of this Website</h3>

<p>The website ${process.env.REACT_APP_PUBLIC_URL} (hereafter referred to as “the Website”) is made available to you on condition that you undertake to abide by these conditions for using the Website (“Terms of Use”).</p>

<p>Euro Disney Associés S.A.S. reserves the right to modify the Terms of Use at any time and without prior notice. We recommend that you regularly check the Terms of Use to acquaint yourself with any changes that may have been made. To see if the Terms of Use have been modified since your last visit, please refer to the date on which the site was last updated, which is displayed at the top of this page.</p>

<p>You must be aged of 18 years or above to register with this Website and comment.</p>

<h3>Services and content on the Website</h3>

<p>The Website provides the following services:</p>

<p>Access to information about Euro Disney Associés S.A.S. and other contents relating to Disneyland® Paris Parks, Disney and Disney/Pixar Characters. This information is made available at no charge to any visitor to the Website;</p>
Functionalities, provided only to the website users (accessibility, contact etc…). Users have the possibility to share contents on third-party websites that are not published by Euro Disney Associés S.A.S. (YouTube, Facebook, Twitter, LinkedIn, etc.).
Modification of these documents such as all copyrights and other proprietary notices is an infringement of the copyright of Disney Enterprises, Inc. (the licensor) and its other proprietary rights.

<h3>Intellectual Property</h3>

<p>Euro Disney Associés S.A.S. is the holder of all rights required for the publication and operation of the Website and its elements (in particular, information, text, images, characters, videos, logos, drawings, brands, graphic displays, data visualisations etc. – hereafter referred to as the “Elements of the Website”).</p>

<p>Reproduction of any content published on our Website is only authorised for informational, non-commercial purposes only and within the limits and conditions of these Terms and Conditions of Use for the duration of the Website’s availability. The Elements of the Website may be used on condition that they are not modified and that all intellectual property notices (in particular copyright) are retained. Euro Disney Associés S.A.S. authorises the use of press releases, computer graphics, press kits and key figures published on the Website by professional journalists solely in the context of their activity.</p>

<h3>Hypertext Links</h3>

<p>Certain hypertext links provided on the Website lead to third-party websites that are not published by Euro Disney Associés S.A.S. (YouTube, Facebook, Twitter, etc.). As a consequence, Euro Disney Associés S.A.S. cannot accept any responsibility for (i) any content or service offered on these third-party sites, (ii) any damage or loss which may result from your using these third-party sites and (iii) the modes of processing of your details on third-party sites.</p>

<p>The placing on third-party sites of links leading to pages of the Website is forbidden, with the exception of links leading to the Website’s home page which may be authorised on condition that (i) the size or appearance of the Elements of the Website is not deleted or modified in any way, (ii) the characters, names, brands and other distinctive signs to which Euro Disney Associés S.A.S. holds the rights of usage and operation on the Website are not used, and (iii) that the third-party site on which the link to the home page of the Website appears complies with regulations applicable to common decency, does not offer defamatory or abusive content and is not of a pornographic nature.</p>

<h3>Availability of the Website</h3>

<p>Euro Disney Associés S.A.S. does not under any circumstances guarantee that access to the Website will be possible at all time on an uninterrupted, instant or automatic basis. Moreover, Euro Disney Associés S.A.S. reserves the right to make the Website unavailable at any time, on a temporary or definitive basis.</p>

<h3>Guarantee and responsibility</h3>

<p>Euro Disney Associés S.A.S. cannot under any circumstance be held responsible for indirect damage resulting from the use of the Website, or inability to access or use all or any part of it, including in particular all or part of the services offered on it. Euro Disney Associés S.A.S. disclaims any guarantee concerning the availability and conformity of the features of the Website and its ability to fulfil the expectations and needs of visitors and Registered Users. Euro Disney Associés S.A.S. does not guarantee that the features and services offered by the Website will be provided without interruption or error, that errors will be corrected, or that the Website or the server on which it is hosted is exempt from viruses or other dangerous components.</p>

<h3>Duration of the Terms of Use</h3>

<p>These Terms of Use apply at all times when you are using the Website, until the Website is permanently made unavailable by Euro Disney.</p>

<h3>Applicable law</h3>

<p>Unless otherwise specified, both these Terms of Use and your relationship with Euro Disney Associés S.A.S. by way of the Website are subject to French law. Any litigation will be the exclusive competence of French courts.</p>
`

export const contentFR = {
  title: "Conditions d'utilisation",
  paraph: textFR
}

export const contentEN = {
  title: "Terms of use",
  paraph: textEN
}