import React, { ReactElement } from "react";
import { Navigate, defer, useLoaderData, useSearchParams } from "react-router-dom";
import { loadLoginInfos } from "../utils/userInfos";

const ProtectedRoutes: React.FC<{ children: React.ReactNode }> = ({ children }): ReactElement => {
  const { LoginInfos } = useLoaderData() as UserInfos;
  const [searchParams] = useSearchParams();
  const lang = searchParams.get('lang');

  if(!lang) {
    return LoginInfos.user ? <>{children}</> : <Navigate to="/login" />;
  } else {
    return LoginInfos.user ? <>{children}</> : <Navigate to={`/login?lang=${lang}`} />;
  }
};

export default ProtectedRoutes;


export async function loader() {
  return defer({
    LoginInfos: await loadLoginInfos(),
  });
}

type UserInfos = {
  LoginInfos: {
    user?: {
      _id: string;
      email: string;
      lastname: string;
      firstname: string;
      cardNumber: string;
      role: string;
    };
  };
};
