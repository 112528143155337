import { initStore, SliceState, StoreState } from './use-store';
 

const configureStore = () => {
  const actions = {
      "TO_FR": (state:SliceState) => {
        return {...state,lang:"FR"}},
      "TO_EN": (state:SliceState) =>{ 
        return {...state, lang: "EN"}},
      "TOGGLE": (state:SliceState) =>{ 
        if(state.lang == "FR"){
          return {...state, lang: "EN"};
        }else{
          return {...state, lang:"FR"}
        }
        },
  }

  initStore("language",actions, {lang: "FR"})
}

export default configureStore;