import React from "react";

import classes from "./LanguageFlag.module.css";

const LanguageFlag: React.FC<Props> = ({ language }) => {
  return (
    <span
      className={classes.container}
      style={{ backgroundImage: `url(${language.img})` }}
    ></span>
  );
};

export default LanguageFlag;

type Props = {
  language: {
    lang: string;
    img: string;
  };
};
