import classes from "./Title.module.css";

const Title: React.FC<{ title: string; subtitle?: string; style?: React.CSSProperties }> = ({
  title,
  subtitle,
  style,
}) => {
  return (
    <>
      <h1 className={classes.edclub_title} style={style}>
        {title}
      </h1>
      {subtitle && <p className={classes.edclub_subtitle}>{subtitle}</p>}
    </>
  );
};

export default Title;
