export function getDateEvent(dateStr: string, lang: "FR" | "EN") {
  // const dateStr = "2023-06-28";
  const date = new Date(dateStr);

  // Array of weekday names
  const weekdays =
    lang === "EN"
      ? ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      : ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];

  // Array of month names
  const months =
    lang === "EN"
      ? [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ]
      : [
          "janvier",
          "février",
          "mars",
          "avril",
          "mai",
          "juin",
          "juillet",
          "août",
          "septembre",
          "octobre",
          "novembre",
          "décembre",
        ];

  // Get the day, month, and date
  const dayName = weekdays[date.getDay()];
  const monthName = months[date.getMonth()];
  const day = date.getDate();

  // Add the appropriate suffix to the day
  let daySuffix;
  if (day === 1 || day === 21 || day === 31) {
    daySuffix = "st";
  } else if (day === 2 || day === 22) {
    daySuffix = "nd";
  } else if (day === 3 || day === 23) {
    daySuffix = "rd";
  } else {
    daySuffix = "th";
  }

  // Format the date string
  let formattedDate;
  if(lang === "EN") {
    formattedDate = `${dayName}, ${monthName} ${day}${daySuffix}, ${date.getFullYear()}`;
  }
  if(lang === "FR") {
    formattedDate = `${dayName} ${day} ${monthName} ${date.getFullYear()}`;
  }

  return formattedDate;
}

export function getHourEvent(timeStr: string, lang: "FR" | "EN") {
  if (lang === "FR") {
    const formattedTime = timeStr.split(':').slice(0, 2).join(':');
    
    return formattedTime;
  }

  if (lang === "EN") {
    const [hour, minute] = timeStr.split(":");
    let adjustedHour = parseInt(hour, 10);
    let meridiem = 'AM';
  
    if (adjustedHour >= 12) {
        meridiem = 'PM';
        if (adjustedHour > 12) adjustedHour -= 12;
    } else if (adjustedHour === 0) {
        adjustedHour = 12;
    }
  
    return `${adjustedHour}:${minute} ${meridiem}`;
  }

}