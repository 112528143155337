import React from "react";
import { faCalendarDays, faClock, faLocationDot } from "@fortawesome/free-solid-svg-icons";

import { EventInterface } from "../models/event";
import classes from "./EventDetails.module.css";
import IconText from "./IconText";
import { SliceState, useStore } from "../store/use-store";
import { getDateEvent, getHourEvent } from "../utils/date";

const EventDetails: React.FC<Props> = ({ eventInfo }) => {
  const [languageStore] = useStore("language") as [SliceState, any];

  return (
    <section className={classes.container}>
      <h1
          dangerouslySetInnerHTML={{
            __html:
            languageStore.lang === "EN" && eventInfo.version_en ? eventInfo.version_en["00_title_en"] : eventInfo.name
          }}></h1>
      <div className={classes.img_metas_wrapper}>
        <div className={classes.img_wrapper}>
          <img src={languageStore.lang === "EN" ? eventInfo.imgUrl_en : eventInfo.imgUrl} alt={eventInfo.name} />
        </div>
        <div className={classes.metas_wrapper}>
          <div className={classes.metas_section}>
            {languageStore.lang === "EN" ? <h3>When</h3> : <h3>Quand</h3>}
            <IconText icon={faCalendarDays} text={getDateEvent(eventInfo.date, languageStore.lang)!} />
            <IconText icon={faClock} text={`${getHourEvent(eventInfo.timeStart, languageStore.lang)} - ${getHourEvent(eventInfo.timeEnd, languageStore.lang)}`} />
          </div>
          <div className={classes.metas_section}>
            {languageStore.lang === "EN" ? <h3>Where</h3> : <h3>Où</h3>}
            <IconText icon={faLocationDot} text={eventInfo.location?.locationName} />
          </div>
        </div>
      </div>
      <div className={classes.metas_section_desc}>
        <h3>Description</h3>
        <div
          dangerouslySetInnerHTML={{
            __html:
              languageStore.lang === "EN" && eventInfo.version_en
                ? eventInfo.version_en["00_description_en"]
                : eventInfo.description,
          }}
        ></div>
      </div>
    </section>
  );
};

export default EventDetails;

type Props = {
  eventInfo: EventInterface;
};
